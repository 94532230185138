import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero/hero"
import RightCta from "../../components/cta/right-bordered/cta"
import LeftCta from "../../components/cta/left-bordered/cta"
import Savings from "../../components/savings/savings";

import desktopHero from "../../images/history/desktopHero.png"
import mobileHero from "../../images/history/mobileHero.png"
import gradientOne from "../../images/history/gradientOne.svg"
import gradientTwo from "../../images/history/gradientTwo.png"

import handInHeart from "../../images/history/3.3_Hand_Heart_Icon-01.png"
import handInHeartMobile from "../../images/history/3.3_Hand_Heart_Icon-01.png"
import vascepaPill from "../../images/history/vascepaPill.png"
import microscopeModule from "../../images/history/microscopeModule.png"


export default () => (
  <Layout>
    <SEO
      title="Learn About Amarin"
      keywords="about AMARIN, AMARIN VASCEPA, history of AMARIN corp"
      description="Learn more about the history of Amarin Corporation plc., a rapidly growing, innovative pharmaceutical company focused on improving cardiovascular (CV) health. Please see Indication and Important Safety Information."
      />

      <Hero
        desktopHeroBackground={desktopHero}
        mobileHeroBackground={mobileHero}
        imgCss="doctorMicroscope"
        alt="Doctor with a microscope"
        headline={"Learn about Amarin"}
        customColumnClass={"desktop-align-self-center"}
        content={"A history of innovation; a limitless future."}/>

      <div className="block row">
        <div style={{padding:"0px", minHeight:"300px"}} className="col-md-5 block-image whiteborderRight left-align-10010">
          <img style={{  height: "100%",
            position: "absolute",
            left: "-9999px",
            right: "-9999px",
            margin: "auto"}} src={vascepaPill}
            alt="Clear VASCEPA® (icosapent ethyl) capsule"
            ></img>
        </div>
        <div className="col-md-7 block-content" >
          <div>
            <h3 className="font-margins"
              style={{fontSize: "24px", color:"#1D355E", lineHeight:"30px", fontWeight:"700"}}
              >Amarin is a rapidly growing, innovative pharmaceutical company focused on commercializing and developing therapeutics to cost-effectively improve cardiovascular health. 
              </h3>
            <p className="font-margins"
              style={{fontSize: "18px", color:"#4D4D4D", lineHeight:"22px", fontWeight:"500"}}
              >Amarin’s lead product, VASCEPA, is a prescription drug. In the United States, VASCEPA is approved and marketed by Amarin’s commercial team for two indications: one for treatment of patients with very high triglyceride levels, and one for cardiovascular risk reduction in addition to medications such as statins in high-risk patients. The latter indication is new (approved by the U.S. Food and Drug Administration in December 2019 following years of development and clinical study) and VASCEPA is the only drug approved for this use. </p>
              <p className="font-margins"
                style={{fontSize: "18px", color:"#4D4D4D", lineHeight:"22px", fontWeight:"500"}}
                >VASCEPA follows strict regulatory standards with a patented 3-month manufacturing process qualified to meet FDA drug standards. This process ensures the consistency and stability of VASCEPA. This is important because, if damaged, the safety and efficacy of the active ingredient can vary and is highly vulnerable to derivatives and oxidation/spoilage. </p>

        </div>
        </div>
      </div>

      <div className="block row">

        <div className="col-md-7 block-content whiteborderRight background-gradient-two center-items" >
          {/* <img class="background-gradient" src={gradientOne}></img> */}
          <div>
            <h3 className="font-margins"
              style={{fontSize: "24px", color:"#1D355E", lineHeight:"30px", fontWeight:"700",zIndex:"1",position:"relative"}}
              >VASCEPA is also available by prescription in Canada, Lebanon, and the United Arab Emirates. Amarin, together with commercial partners in select geographies, is pursuing additional regulatory approvals for VASCEPA in China, the European Union, and other regions within the Middle East.
               </h3>
            <p className="font-margins"
              style={{fontSize: "18px", color:"#4D4D4D", lineHeight:"22px", fontWeight:"500",zIndex:"1",position:"relative"}}
              >For more information about Amarin, visit <a style={{color:"rgb(77, 77, 77)",textDecoration:"underline"}} target="_blank" href="https://www.amarincorp.com">www.amarincorp.com</a>.</p>
        </div>

        </div>

        <div style={{padding:"0px", minHeight:"300px"}} className="col-md-5 block-image ">
          <img
            alt="" 
            style={{  height: "100%",
            position: "absolute",
            left: "-9999px",
            right: "-9999px",
            margin: "auto"}} src={microscopeModule}></img>
        </div>

      </div>

      <RightCta
        colOne="5"
        colTwo="7"
        whiteborder="whiteborder"
        image={handInHeart}
        alt="Hand heart icon"
        mobileImage={handInHeartMobile}
        background={gradientTwo}
        headline="Find out how VASCEPA can provide additional heart protection."
        cta={["VASCEPA benefits ",<span aria-hidden="true">&gt;</span>]}
        url="/benefits"
        />

  </Layout>
)
