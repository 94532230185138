import React from "react";
import { Link } from "gatsby"
import "../cta.css"

import pause from "../../../images/pause.svg";
import play from "../../../images/play.svg";

import Vimeo from "@vimeo/player";

export default class LeftWhiteBorderedCta extends React.Component {

    constructor(props) {
       super(props);
       this.state={
         mobile : false,
         paused: true
       }
    }
    componentDidMount(){
      window.innerWidth < 1000 ? this.setState({mobile:true}) : this.setState({mobile:false})
    }

    togglePlay = (event) => {
      if(event.key !== "Tab"){
      if (this.state.paused === true) {
        this.setState({
          paused : false 
        })
        this.toggleVideo(event,false)
      }
      else{
        this.setState({
          paused: true 
        })
        this.toggleVideo(event,true)
      }
      }
    }

    toggleVideo = (event,pause) => {
      var element = event.target.parentElement
      var iframe = element.querySelector('iframe');
      if ( iframe ) {
        var player = new Vimeo(iframe);
        pause ? player.play() : player.pause()

      }
      
     }

render(){
    var mobile
    mobile = this.state.mobile
    return (
      <div className="cta-outer" id={this.props.componentID}>
        <div className={this.props.containerClass + " block row"}>
          <div className={"order-sm-12 col-md-"+this.props.colTwo+" block-image " + this.props.imgSM}>
            <span className="imageLabel">{this.props.imageLabel}</span>
             {/*console.log(this.props.mobileImage*/}
             {/*console.log(mobile)*/}
             {/*console.log(this.props.video)*/}
            {
              mobile ?
              this.props.video? this.props.mobileIframe : <img src={this.props.mobileImage} className={this.props.mobileImageClass} alt={this.props.alt}></img>
              :
              this.props.video? this.props.iframe : <img src={this.props.image} className={this.props.imgBackground} alt={this.props.alt}></img>
            }
             {/* {this.props.video && this.state.paused
              ? <img className="pause" alt="pause button" onClick={this.togglePlay} src={pause}></img> 
              : <img className="play" alt="play button" onClick={this.togglePlay} src={play}></img>
             } */}


            {this.props.video ? 
            this.state.paused? <img className="pause" alt="pause button" tabIndex="0" onKeyDown={this.togglePlay} onClick={this.togglePlay} src={pause}></img> 
            : <img className="play" alt="play button" tabIndex="0" onKeyDown={this.togglePlay} onClick={this.togglePlay} src={play}></img>
            : null}
            
            <div style={{height:"100%", width:"100%", backgroundImage:this.props.backgroundImage, backgroundSize:"100%, auto" }}></div>
          </div>
            <div className={"order-sm-6 col-md-"+this.props.colThree} ></div>
            <div style={{background:this.props.backgroundGradient}} className={"order-sm-1 col-md-"+this.props.colOne+" block-content align-block-center "  + this.props.whiteborder}>
              {this.props.background ? <img className="background-gradient" alt="" src={this.props.background}></img> : null }
              <div style={{height:"100%",width:"100%"}} className="row">

                <div style={{textAlign:"left"}} className={this.props.contentClass + " block-headline col-md-12 position-relative m-p-cta " + this.props.contentSubClass}>

                  <h3 style={{color:this.props.headlineColor}} className={this.props.contentHeadline + " style-h2"}>{this.props.headline}</h3>
                    <p style={{color:this.props.headlineColor}} className=" d-none">{this.props.content}</p>

                </div>
                <div style={this.props.ctaBackground ? {backgroundImage:`url(${this.props.ctaBackground})`,backgroundSize: 'cover'} : null} className={this.props.ctaSubClass+ " cta"}>
                  {this.props.external === "true" ? <a style={{color: this.props.ctaColor}} target="_blank" href={this.props.url} className="my-auto style-a1 m-p-x-20">{this.props.cta}</a> : <Link style={{color: this.props.ctaColor}} to={this.props.url} className="my-auto style-a1 m-p-x-20">{this.props.cta}</Link>}
                </div>
            </div>
            </div>
        </div>
      </div>
    )
}
}


LeftWhiteBorderedCta.defaultProps = {
  containerClass : "cta-container",
  contentClass : "cta-content",
  alt: " ",
  video: false
}
